import './App.css';

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import NavBar from './components/navigation/NavBar';

import Leistungsberechnung from './pages/Leistungsberechnung';
import Zeitberechnung from './pages/Zeitberechnung';
import CpAwcBerechnung from './pages/CpAwcBerechnung/CpAwcBerechnung';
import { useEffect } from 'react';
function App() {

  useEffect(() => {
    var _paq = window._paq = window._paq || [];
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(["disableCookies"]);
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function () {
      var u = "//analytics.dkueffer.ch/";
      _paq.push(['setTrackerUrl', u + 'matomo.php']);
      _paq.push(['setSiteId', '6']);
      var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
      g.async = true; g.src = u + 'matomo.js'; s.parentNode.insertBefore(g, s);
    })();
  }, [])

  return (
    <div>
      <NavBar />
      <Routes>
        <Route path="/leistungsberechnung" element={<Leistungsberechnung />} />
        <Route path="/zeitberechnung" element={<Zeitberechnung />} />
        <Route path="/zeitprognose" element={<Leistungsberechnung />} />

        <Route path="/cp-awc-berechnung" element={<CpAwcBerechnung />} />

        <Route path="/" element={<Navigate to="/leistungsberechnung" />} />

      </Routes>
    </div>
  );
}

export default App;

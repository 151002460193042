import React from 'react';
import { NavLink } from 'react-router-dom';

export default function NavItem(props) {
    const { name, link } = props;
    return (<NavLink to={link} className="nav-item">
        <div >
            {name}
        </div></NavLink>
    )
}

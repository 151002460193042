import React from 'react';
import katex from 'katex';
import 'katex/dist/katex.min.css';
import './formula-printer.scss';
const FormulaPrinter = (props) => {

    const { formula } = props;

    return (
        <div className="formula-printer">
            <div dangerouslySetInnerHTML={{ __html: katex.renderToString(formula) }} />
        </div>
    );
};

export default FormulaPrinter;

import React, { useEffect } from 'react';
import './number-input.scss';
import PropTypes from 'prop-types';

function SelectInput({ label, value, options, onChange }) {
    const handleChange = (e) => {
        const selectedValue = e.target.value;
        onChange(selectedValue);
    };

    const checkIfValueIsInOptions = (value) => {

        return options.some((option) => option.value === parseFloat(value));
    }


    useEffect(() => {
        onChange(options[0].value);
    }, []);

    return (
        <div className="select-input">
            <label>{label}</label>
            <select
                value={checkIfValueIsInOptions(value) ? value : ''}
                onChange={handleChange}
            >
                <option value="" disabled>
                    Bitte wählen
                </option>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
}

SelectInput.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string,
        })
    ).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default SelectInput;
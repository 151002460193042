import React from 'react';
import { Line } from 'react-chartjs-2';

import { useTranslation } from 'react-i18next';

function QuadraticGraph({ parameter, title }) {
    const { t } = useTranslation();

    const xValues = Array.from({ length: 61 }, (_, index) => index); // x-values from 0 to 60

    const resistanceTypes = Object.keys(parameter);

    const datasets = resistanceTypes.map((resistanceType) => {
        const resistanceData = parameter[resistanceType];
        const label = `${resistanceType.charAt(0).toUpperCase() + resistanceType.slice(1)} Line`;

        const dataset = {
            label: label.split(' ')[0],
            data: xValues.map((x) => resistanceData.a * (x / 3.6) * (x / 3.6) * (x / 3.6) + resistanceData.b * (x / 3.6) + resistanceData.c),
            fill: false,
            borderColor: getColor(label), // Assign a random color to each line
            backgroundColor: getColor(label), // Assign a random color to each line
            pointRadius: 0, // Set point radius to 0 to hide data points
            pointHitRadius: 0, //
        };

        return dataset;
    });

    const chartData = {
        labels: xValues,
        datasets: datasets,
    };

    const chartOptions = {
        plugins: {
            title: {
                display: true,
                text: title, // Replace with your desired title
                padding: {
                    top: 10, // Adjust top padding as needed
                    bottom: 10, // Adjust bottom padding as needed
                },
            },
            legend: {
                position: 'top',
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: t('Geschwindigkeit [km/h]'),
                },
            },
            y: {
                title: {
                    display: true,
                    text: t('Leistung [W]'),
                },
            },
        },
    };



    return (
        <div>
            <Line data={chartData} options={chartOptions} />
        </div>
    );
}



const getColor = (label) => {
    if (label === 'Luftwiderstand Line') {
        return '#9BCBF0';
    } else if (label === 'Steigungswiderstand Line') {
        return '#FD9FB0';
    } else if (label === 'Rollwiderstand Line') {
        return '#FBC394';
    } else {
        return 'rgba(75, 192, 192, 1)';
    }
}


export default QuadraticGraph;

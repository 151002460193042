export default class ChartDataProvider {

    constructor(calculator1, calculator2) {
        this.calculator1 = calculator1;
        this.calculator2 = calculator2;
    }

    getStackedBarChartData() {

        const airResistance1 = this.calculator1?.calculateAirResistancePercentage(null);
        const slopeResistance1 = this.calculator1?.calculateSlopeResistancePercentage(null);
        const rollwiderstand1 = this.calculator1?.calculateRollwiderstandPercentage(null);


        const airResistance2 = this.calculator2?.calculateAirResistancePercentage(null);
        const slopeResistance2 = this.calculator2?.calculateSlopeResistancePercentage(null);
        const rollwiderstand2 = this.calculator2?.calculateRollwiderstandPercentage(null);

        return {
            labels: ["Szenario 1", "Szenario 2"],
            datasets: [
                {
                    label: 'Luftwiderstand', // Use the label as the dataset label
                    data: [airResistance1, airResistance2] // Extract the data for the third label
                },
                {
                    label: 'Steigungswiderstand', // Use the label as the dataset label
                    data: [slopeResistance1, slopeResistance2] // Extract the data for the first label
                },
                {
                    label: 'Rollwiderstand',
                    data: [rollwiderstand1, rollwiderstand2] // Extract the data for the second label
                }
            ]
        }
    }

    calculatePowerGraphParameter(state, decimalPlaces = 2) {
        const { airDensity, bikePosition } = state['scenario1'];
        const area = this.calculator1.calculateArea(state, null);

        return {
            scenario1: {
                Steigungswiderstand: {
                    a: 0,
                    b: this.calculator1.calculateSlopeResistance(null),
                    c: 0
                },
                Luftwiderstand: {
                    a: 0.5 * airDensity * area * bikePosition,
                    b: 0,
                    c: 0
                },
                Rollwiderstand: {
                    a: 0,
                    b: this.calculator1.calculateRollwiderstand(null),
                    c: 0
                },
                Gesamtwiderstand: {
                    a: 0.5 * airDensity * area * bikePosition,
                    b: this.calculator1.calculateRollwiderstand(null) + this.calculator1.calculateSlopeResistance(null),
                    c: 0
                },
            },
            scenario2: {
                Steigungswiderstand: {
                    a: 0,
                    b: this.calculator2.calculateSlopeResistance(null),
                    c: 0
                },
                Luftwiderstand: {
                    a: 0.5 * airDensity * area * bikePosition,
                    b: 0,
                    c: 0
                },
                Rollwiderstand: {
                    a: 0,
                    b: this.calculator2.calculateRollwiderstand(null),
                    c: 0
                },
                Gesamtwiderstand: {
                    a: 0.5 * airDensity * area * bikePosition,
                    b: this.calculator2.calculateRollwiderstand(null) + this.calculator2.calculateSlopeResistance(null),
                    c: 0
                },
            }
        }
    }

    calculatePowerPerVelocity(decimalPlaces = 2) {

        const dataScenario1 = [];
        const dataScenario2 = [];


        for (let i = 1; i <= 60; i++) {

            const slopeResistance = this.calculator1.calculateSlopeResistance(null);
            const airResistance = this.calculator1.calculateAirResistanceFromVelocity(i, null);
            const rollwiderstand = this.calculator1.calculateRollwiderstand(null);
            const totalResistance = slopeResistance + airResistance + rollwiderstand;

            dataScenario1.push({ slopeResistance: slopeResistance / totalResistance * 100, airResistance: airResistance / totalResistance * 100, rollwiderstand: rollwiderstand / totalResistance * 100 });

        }

        for (let i = 1; i <= 60; i++) {

            const slopeResistance = this.calculator2.calculateSlopeResistance(null);
            const airResistance = this.calculator2.calculateAirResistanceFromVelocity(i, null);
            const rollwiderstand = this.calculator2.calculateRollwiderstand(null);
            const totalResistance = slopeResistance + airResistance + rollwiderstand;

            dataScenario2.push({ slopeResistance: slopeResistance / totalResistance * 100, airResistance: airResistance / totalResistance * 100, rollwiderstand: rollwiderstand / totalResistance * 100 });

        }


        let numbersArray = [];

        for (let i = 1; i <= 60; i++) {
            numbersArray.push(i);
        }

        return {
            scenario1:
            {
                labels: numbersArray,
                datasets: [

                    {
                        label: 'Steigungswiderstand', // Use the label as the dataset label
                        data: dataScenario1.map(function (item) {
                            return item.slopeResistance;
                        }),
                        backgroundColor: "#FD9FB0",
                        borderColor: "#FD9FB0",

                    },
                    {
                        label: 'Rollwiderstand',
                        data: dataScenario1.map(function (item) {
                            return item.rollwiderstand;
                        }),
                        backgroundColor: "#FBC394",
                        borderColor: "#FBC394",
                    },
                    {
                        label: 'Luftwiderstand', // Use the label as the dataset label
                        data: dataScenario1.map(function (item) {
                            return item.airResistance;
                        }),
                        backgroundColor: "#9BCBF0",
                        borderColor: "#9BCBF0",

                    }
                ]
            },
            scenario2:
            {
                labels: numbersArray,
                datasets: [

                    {
                        label: 'Steigungswiderstand', // Use the label as the dataset label
                        data: dataScenario2.map(function (item) {
                            return item.slopeResistance;
                        }),
                        backgroundColor: "#FD9FB0",
                        borderColor: "#FD9FB0",

                    },
                    {
                        label: 'Rollwiderstand',
                        data: dataScenario2.map(function (item) {
                            return item.rollwiderstand;
                        }),
                        backgroundColor: "#FBC394",
                        borderColor: "#FBC394",
                    },
                    {
                        label: 'Luftwiderstand', // Use the label as the dataset label
                        data: dataScenario2.map(function (item) {
                            return item.airResistance;
                        }),
                        backgroundColor: "#9BCBF0",
                        borderColor: "#9BCBF0",

                    }
                ]
            }
        }

    }

}




import React, { Fragment } from 'react';
import './info-table.scss';
import { useTranslation } from 'react-i18next';
import katex from 'katex';
import 'katex/dist/katex.min.css';

const inlineFormula = (formula, unit) => {
    if (!formula && !unit) {
        return null;
    }
    let formulaWithUnit = '';
    if (!formula) {
        formulaWithUnit = `\\text{ [${unit}]}`;
    } else if (!unit) {
        formulaWithUnit = `${formula}`;
    } else {
        formulaWithUnit = `${formula} \\text{ [${unit}]}`;
    }


    return <span
        dangerouslySetInnerHTML={{
            __html: katex.renderToString(formulaWithUnit, {
                throwOnError: false,
            }),
        }}
    />
}

const Row = (props) => {
    const { label, valueScenario1, valueScenario2, formula, unit } = props;
    return (
        <Fragment>
            <div>{label} {inlineFormula(formula, unit)}</div>
            <div>{valueScenario1}</div>
            <div>{valueScenario2}</div>
        </Fragment>
    )
}

const Heading = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div className="title"></div>
            <div className="title"> {t('Szenario 1')}</div>
            <div className="title"> {t('Szenario 2')}</div>
        </Fragment>
    )
}

const katexFormula = '\\sqrt{2}';

// Render the KaTeX formula to HTML
const katexHTML = katex.renderToString(katexFormula, {
    throwOnError: false, // optional, suppress rendering errors
});


export default function InfoTable(props) {
    const { title, rows, explanation } = props;



    return (
        <div>
            <h2>{title}</h2>
            <div>
                {explanation ? explanation : null}</div>
            <div className="info-table">
                <Heading />
                {rows.map((row) => {
                    return (
                        <Row
                            key={row.label + '-' + title}
                            label={row.label}
                            valueScenario1={row.valueScenario1}
                            valueScenario2={row.valueScenario2}
                            formula={row?.formula}
                            unit={row?.unit}
                        />
                    )
                })}
            </div>
        </div>
    )
}


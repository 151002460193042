const ERDANZIEHUNG = 9.81;
const WIRKUNGSGRAD = 0.985;
const ANTRIEBSVERLUST = 3;


const steigungswiderstandFormula = 'F_{\\text{slope}} = m \\cdot g \\cdot \\sin(\\alpha)';
const rollwiderstandFormula = 'F_{\\text{roll}} = m \\cdot g \\cdot C_r \\cdot \\cos(\\alpha)';
const luftwiderstandFormula = 'F_{\\text{air}} = 0.5 \\cdot \\rho \\cdot C_d \\cdot A \\cdot (v - v_{\\text{wind}})^2';
const PnetFormula = 'P_{\\text{net}} = F_{\\text{air}} \\cdot v + F_{\\text{slope}} \\cdot v + F_{\\text{roll}} \\cdot v';
const PbrutFormula = 'P_{\\text{brut}} =\\frac{P_{\\text{net}}}{\\text{Wirkungsgrad}} + \\text{Antriebswiderstand}';


export { ERDANZIEHUNG, WIRKUNGSGRAD, ANTRIEBSVERLUST, steigungswiderstandFormula, rollwiderstandFormula, luftwiderstandFormula, PnetFormula, PbrutFormula };
import React, { useState } from 'react';
import GreyBox from '../../components/GreyBox';

import CpAwcTable from './CpAwcTable';
import Optimizer from './Optimizer';

export default function CpAwcBerechnung() {

    const [data, setData] = useState([]);




    return (
        <div className="page grid-container">
            <GreyBox className="medium-6">

                <CpAwcTable setData={setData} />
                <Optimizer input={data} />

            </GreyBox>

        </div>
    )
}

import React from 'react'
import './scenario-result.scss';
import { useTranslation } from 'react-i18next';

export default function ScenarioResult(props) {
    const { t } = useTranslation();
    return (
        <div className="scenario-result">
            <div className='scenario-result__column'>
                <span className="scenario-result__label">{t('Sekunden')}</span>
                <span className="scenario-result__value">{props.sekunden}</span>
            </div>
            <div className='scenario-result__column'>
                <span className="scenario-result__label">{t('Brutto-Gesamtleistung [W]')}</span>
                <span className="scenario-result__value">{props.gesamtleistung}</span>
            </div>
            <div className='scenario-result__column'>
                <span className="scenario-result__label">{t('Relative Leistung [W/kg]')}
                </span>
                <span className="scenario-result__value">{props.relative_leistung}</span>
            </div>
        </div>
    )
}

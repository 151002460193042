import React, { useEffect, useRef } from 'react';
import IMask from 'imask';

export default function MaskedInput(props) {
    const { mask, placeholder, value, onChange } = props;
    const inputRef = useRef(null);

    useEffect(() => {
        const maskOptions = {
            mask,
            // Add any other options you need, such as pattern, format, etc.
        };

        const maskInstance = IMask(inputRef.current, maskOptions);

        return () => {
            maskInstance.destroy();
        };
    }, [mask]);

    return <input ref={inputRef} value={value} onChange={onChange} type="text" placeholder={placeholder} />;
}
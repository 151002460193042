import React, { createContext, useContext, useReducer, useMemo } from 'react';

const initialState = {
    scenario1: {
        height: 1.85,
        heightDifference: 230,
        bodyWeight: 78,
        bikePosition: 0,
        bikeWeight: 11.6,
        clothingWeight: 3,
        additionalWeight: 0,
        distance: 11.2,
        cr: 0,
        airDensity: 1.23,
        tailwind: 0,
        zielleistung: 250,

        time: {
            hours: 0,
            minutes: 12,
            seconds: 18,
        },
        dataPoints: [],
    },
    scenario2: {
        height: 1.58,
        heightDifference: 0,
        bodyWeight: 59,
        bikePosition: 0,
        bikeWeight: 9,
        clothingWeight: 2.3,
        additionalWeight: 0,
        distance: 9.9,
        cr: 0,
        airDensity: 1.23,
        tailwind: 0,
        zielleistung: 500,
        time: {
            hours: 0,
            minutes: 9,
            seconds: 5,
        },
        dataPoints: [],
    }
};

function reducer(state, action) {
    switch (action.type) {
        case 'UPDATE_SCENARIO1':
            return {
                ...state,
                scenario1: { ...state.scenario1, ...action.payload },
            };
        case 'UPDATE_SCENARIO2':
            return {
                ...state,
                scenario2: { ...state.scenario2, ...action.payload },
            };
        case 'UPDATE_SCENARIO':
            const { scenarioName, payload } = action;
            if (state.hasOwnProperty(scenarioName)) {
                return {
                    ...state,
                    [scenarioName]: { ...state[scenarioName], ...payload },
                };
            }
            return state;
        default:
            return state;
    }
}



const CyclingDynamics = createContext();

function CyclingDynamicsContextProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const memoizedValue = useMemo(() => {
        const updateScenario = (value, key, scenarioName) => {
            const updatedData = { [key]: value };

            dispatch({
                type: 'UPDATE_SCENARIO',
                scenarioName,
                payload: updatedData,
            });
        };

        return { state, updateScenario };
    }, [state, dispatch]);

    return (
        <CyclingDynamics.Provider value={memoizedValue}>
            {children}
        </CyclingDynamics.Provider>
    );
}




function useCyclingDynamics() {
    return useContext(CyclingDynamics);
}

CyclingDynamicsContextProvider.defaultProps = {
    children: null,
};


export { CyclingDynamicsContextProvider, useCyclingDynamics };
import React from 'react';
import MaskedInput from './MaskedInput';

export default function TimeInput(props) {
    const { value, onChange } = props;

    const handleChange = (e) => {
        e.preventDefault();
        onChange(e.target.value);
    }

    return (
        <MaskedInput
            mask="00:00:00"
            placeholder="hh:mm:ss"
            type="time"
            value={value}
            onChange={handleChange}
        />
    );
}

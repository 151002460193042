import React from 'react';
import { Line, Scatter } from 'react-chartjs-2';

const LogLogChart = ({ coefficients, dataPoints }) => {
    if (!coefficients) {
        return null;
    }
    const chartData = generateChartData(coefficients);



    const chartOptions = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'log time',
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'log power',
                },
            },
        },

    };

    function generateChartData(coefficients) {
        if (!coefficients) {
            return {};
        }


        const timeValues = [];
        const powerValues = [];

        for (let time = 0; time <= 10; time += 1) {
            timeValues.push(time);
        }


        console.log('datapoints', dataPoints)
        console.log(coefficients)
        const regressionLine = [{ x: 0, y: coefficients[0] }, { x: 12, y: coefficients[1] * 12 + coefficients[0] }];

        const tmpDataPoints = dataPoints.map((point) => { return { x: point[0], y: point[1] } })
        console.log(tmpDataPoints)
        return {
            datasets: [
                {
                    label: 'Single Point',
                    data: tmpDataPoints, // Add your single point data here
                    pointBackgroundColor: 'red', // Set the point color
                    pointRadius: 3, // Set the point size
                    showLine: false, // Hide the line connecting the points
                    pointBorderColor: 'transparent', // Removes the border around the point

                },
                {
                    label: 'Regression Line',
                    data: regressionLine,
                    borderColor: 'rgba(0, 123, 255, 0.5)', // Light blue with transparency
                    backgroundColor: 'rgba(0, 123, 255, 0.1)', // Light blue with more transparency (less visible)
                    pointRadius: 0, // Hide the points on the regression line
                    showLine: true, // Show the line connecting the points
                    fill: false, // Do not fill under the line
                    borderWidth: 2, // You can adjust the thickness of the line
                },
            ]
        };
    }


    return (
        <div>
            <h2>Log-Log Chart</h2>
            <Scatter data={chartData} options={chartOptions} />
        </div>
    );
};

export default LogLogChart;

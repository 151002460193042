// src/components/PieChart.js
import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";

import { useTranslation } from 'react-i18next';








function PieChart({ chartData, title, xLabel }) {

    const { t } = useTranslation();

    const options = {
        plugins: {
            title: {
                display: true,
                text: title,
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                title: {
                    display: xLabel,
                    text: t('Geschwindigkeit [km/h]'),
                },
            },
            y: {
                stacked: true,
                max: 100,
                min: 0,
                title: {
                    display: true,
                    text: t('Gesamtwiderstand [%]'),
                },
            },
        },
        maintainAspectRatio: false,

    }


    return (
        <div className="chart-container">
            <div style={{ height: '400px' }}>
                <Bar options={options} data={chartData} />
            </div>
        </div>
    );
}
export default PieChart;
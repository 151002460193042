import React, { memo } from 'react';
import './number-input.scss';

const NumberInput = memo(({ label, value, onChange }) => {
    const handleChange = (e) => {
        const newValue = parseFloat(e.target.value);
        if (!isNaN(newValue)) {
            onChange(newValue);
        }
    };

    return (
        <div className="number-input">
            <label>{label}</label>
            <input
                type="number"
                value={value}
                onChange={handleChange}
            />
        </div>
    );
});

export default NumberInput;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


import de from './i18n/de.json';
import fr from './i18n/fr.json';
import en from './i18n/en.json';

const localStorageLanguage = localStorage.getItem('language');

i18n
    .use(initReactI18next)
    .init({
        resources: {
            de: {
                translation: de
                ,
            },
            fr: {
                translation: fr,
            },
            en: {
                translation: en,
            }
        },
        lng: localStorageLanguage || 'de',
        fallbackLng: 'de', // Default language
        debug: false, // Enable debugging in development
    });


export default i18n;

import React, { useEffect, useState } from 'react';
import { levenbergMarquardt } from 'ml-levenberg-marquardt';

import { useCyclingDynamics } from '../../context/CyclingDynamicsContext';
import PowerTimeChart from '../PowerTimeChart';
import WorkTimeChart from '../WorkTimeChart';
import { SimpleLinearRegression } from 'ml-regression-simple-linear';
import CriticalPowerChart from '../CriticalPowerChart'
import LogLogChart from '../LogLogChart';
import { round } from '../../helpers/helpers'

function Optimizer() {

    const { state } = useCyclingDynamics();
    const [result, setResult] = useState({});
    const [coefficients, setCoefficients] = useState(null)

    const [logCoefficients, setLogCoefficients] = useState(null);

    const minValues = [0, 0];


    function power_time([AWC, CP]) {
        return (power) => AWC / (power - CP);
    }

    const calculateNonLinearRegression = (initialValues) => {

        initialValues = [initialValues[0] - 0.01 * initialValues[0], initialValues[1] - 0.01 * initialValues[1]];

        const time = state['scenario1'].dataPoints.map((row) => row[0]);
        const power = state['scenario1'].dataPoints.map((row) => parseFloat(row[1]));

        if (power.length < 2 || time.length < 2) {
            setResult({});
            return;
        };

        const tmpResult = levenbergMarquardt({ x: power, y: time }, power_time, { initialValues: initialValues, minValues: minValues, maxIterations: 1000 });
        setResult(tmpResult);
        console.log(tmpResult);
    }



    useEffect(() => {
        const x = state['scenario1'].dataPoints.map((row) => row[0]);
        const y = state['scenario1'].dataPoints.map((row) => row[0] * parseFloat(row[1]));

        const log_x = state['scenario1'].dataPoints.map((row) => Math.log(parseFloat(row[0])));
        const log_y = state['scenario1'].dataPoints.map((row) => Math.log(parseFloat(row[1])));

        const regression = new SimpleLinearRegression(x, y);
        const log_regression = new SimpleLinearRegression(log_x, log_y);


        setCoefficients(regression.coefficients);
        setLogCoefficients(log_regression.coefficients)
        calculateNonLinearRegression(regression.coefficients);
    }, [state]);



    return (
        <div>

            <h3>Work-Time-Model:</h3>
            <div>AWC: {coefficients?.length === 2 ? round(coefficients[0], 0) + 'KJ' : null}</div>
            <div>CP: {coefficients?.length === 2 ? round(coefficients[1], 0) + 'W' : null}</div>

            <h3>Power-Time-Model:</h3>
            <div>AWC: {result?.parameterValues?.length === 2 ? round(result.parameterValues[0], 0) + 'KJ' : null}</div>
            <div>CP: {result?.parameterValues?.length === 2 ? round(result.parameterValues[1], 0) + 'W' : null}</div>

            <div>
                <CriticalPowerChart
                    dataPoints={state['scenario1'].dataPoints.map(([x, y]) => [parseFloat(x), parseFloat(y)])}
                    AWC={coefficients?.length === 2 ? coefficients[0] : null}
                    CP={coefficients?.length === 2 ? coefficients[1] : null} />

                <WorkTimeChart
                    dataPoints={state['scenario1'].dataPoints.map(([x, y]) => [parseFloat(x), parseFloat(x) * parseFloat(y)])}
                    coefficients={coefficients} />

                <PowerTimeChart
                    dataPoints={state['scenario1'].dataPoints.map(([x, y]) => [parseFloat(x), parseFloat(y)])}
                    AWC={result?.parameterValues?.length === 2 ? result.parameterValues[0] : null}
                    CP={result?.parameterValues?.length === 2 ? result.parameterValues[1] : null} />
                <LogLogChart
                    dataPoints={state['scenario1'].dataPoints.map(([x, y]) => [Math.log(parseFloat(x)), Math.log(parseFloat(y))])}
                    coefficients={logCoefficients} />
            </div>

        </div>
    );
}

export default Optimizer;

import React, { useCallback, memo } from 'react';
import NumberInput from './NumberInput';
import { useCyclingDynamics } from '../../context/CyclingDynamicsContext';
import './scenario-input.scss';
import SelectInput from './SelectInput';
import TimeInput from './TimeInput';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';


const ScenarioInput = memo((props) => {
    const { updateScenario, state } = useCyclingDynamics();
    const { t } = useTranslation();

    const { title, scenarioName, zeitberechnung, children } = props;


    const inputFields = [
        {
            label: t('Körpergrösse [m]'),
            dataKey: 'height',
            type: 'number',
        },
        {
            label: t('Körpergewicht [kg]'),
            dataKey: 'bodyWeight',
            type: 'number',
        },
        {
            label: t('Position Fahrrad'),
            dataKey: 'bikePosition',
            type: 'select',
            options: [
                {
                    label: t('Bremsgriff'),
                    value: 1.0,
                },
                {
                    label: t('Oberlenker'),
                    value: 1.15,
                },
                {
                    label: t('Unterlenker'),
                    value: 0.88,
                },
                {
                    label: t('Zeitfahrlenker'),
                    value: 0.7,
                },
                {
                    label: t('Aeroposition'),
                    value: 0.59,
                },
            ],
        },
        {
            label: t('Position Fahrrad (Cd)'),
            dataKey: 'bikePosition',
            type: 'number',
            key: 'cd_custom'
        },
        {
            label: t('Fahrrad Gewicht [kg]'),
            dataKey: 'bikeWeight',
            type: 'number',
        },
        {
            label: t('Bekleidung Gewicht [kg]'),
            dataKey: 'clothingWeight',
            type: 'number',
        },
        {
            label: t('Zusatzgewicht [kg]'),
            dataKey: 'additionalWeight',
            type: 'number',
        },
        {
            label: t('Distanz (Hypothenuse) [km]'),
            dataKey: 'distance',
            type: 'number',
        },
        {
            label: t('Höhendifferenz [m]'),
            dataKey: 'heightDifference',
            type: 'number',
        },
        {
            label: t('Untergrund (Cr)'),
            dataKey: 'cr',
            type: 'select',
            options: [
                {
                    label: t('Normaler Asphalt'),
                    value: 0.0070,
                },
                {
                    label: t('Holzbahn'),
                    value: 0.0015,
                },
                {
                    label: t('Feiner Asphalt'),
                    value: 0.0040,
                },
                {
                    label: t('Grober Asphalt'),
                    value: 0.0085,
                },
                {
                    label: t('Betonbelag'),
                    value: 0.0082,
                },
                {
                    label: t('Kopfsteinpflaster'),
                    value: 0.0092,
                },
            ],
        },
        {
            label: t('Rollwiderstandskoeffizient (Cr)'),
            dataKey: 'cr',
            type: 'number',
            key: 'cr_custom'
        },
        {
            label: t('Luftdichte [kg/m3]'),
            dataKey: 'airDensity',
            type: 'number',
        },
        {
            label: t('Rückenwind [km/h]'),
            dataKey: 'tailwind',
            type: 'number',
        },
        {
            label: t('Gegenwind [km/h]'),
            dataKey: 'tailwind',
            type: 'number',
            negative: true,
            key: 'tailwindNegative'
        },

    ];


    const memoizedTimeInputChange = useCallback((value, dataKey) => {
        updateScenario(value, dataKey, scenarioName);
    }, []);

    const memoizedOnChange = useCallback(
        (inputField) => (value) => {
            updateScenario(
                inputField.negative ? -value : value,
                inputField.dataKey,
                scenarioName
            );
        },
        []
    );


    return (
        <div>
            <h2>{title}</h2>
            <div className="scenario-input__fields">
                {inputFields.map((inputField) => (

                    inputField.type === 'number' ? (
                        <NumberInput
                            key={inputField.key ? inputField.key : inputField.dataKey}
                            label={inputField.label}
                            value={inputField.negative ? -state[scenarioName][inputField.dataKey] : state[scenarioName][inputField.dataKey]}
                            onChange={memoizedOnChange(inputField)}
                        />
                    ) : inputField.type === 'time' ? (
                        <TimeInput
                            key={inputField.dataKey}
                            label={inputField.label}
                            value={state[scenarioName][inputField.dataKey]}
                            onChange={memoizedTimeInputChange}
                        />
                    ) : (
                        <SelectInput
                            key={inputField.dataKey}
                            label={inputField.label}
                            value={state[scenarioName][inputField.dataKey]}
                            options={inputField.options}
                            onChange={(value) => updateScenario(value, inputField.dataKey, scenarioName)}
                        />
                    )
                ))}
                {
                    zeitberechnung === true ?
                        <NumberInput
                            key={'zielleistung'}
                            label={i18n.t('Zielleistung')}
                            value={state[scenarioName]['zielleistung']}
                            onChange={(value) => updateScenario(value, 'zielleistung', scenarioName)}
                        />
                        : <TimeInput
                            key={'time'}
                            label={i18n.t('Zeit') + ' [hh:mm:ss]'}
                            value={state[scenarioName]['time']}
                            onChange={(value) => updateScenario(value, 'time', scenarioName)}
                        />

                }
                {children}
            </div>

        </div>
    );
});


ScenarioInput.defaultProps = {
    children: null,
    zeitberechnung: false,
};

ScenarioInput.propTypes = {
    title: PropTypes.string.isRequired,
    scenarioName: PropTypes.string.isRequired,
    zeitberechnung: PropTypes.bool,
    children: PropTypes.node,
};

export default ScenarioInput;
import React from 'react';
import './nav-bar.scss';
import NavItem from './NavItem';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';



const changeLanguage = (lng) => {
    i18next.changeLanguage(lng);
    localStorage.setItem('language', lng)
}

export default function NavBar() {
    const { t } = useTranslation();
    const navItems = [
        {
            name: t("Leistungsberechnung"),
            link: "/leistungsberechnung"
        }, {
            name: t("Zeitberechnung"),
            link: "/zeitberechnung"
        }
    ];
    return (
        <div className="nav-bar">
            <div className="nav-bar__brand">Swiss Cycling</div>

            <div className="nav-bar-links">
                {navItems.map((item, index) => {
                    return <NavItem key={index} name={item.name} link={item.link} />

                })}
                <div className="language-switcher">
                    <span onClick={() => changeLanguage('de')}>DE</span>
                    <span onClick={() => changeLanguage('fr')}>FR</span>
                    <span onClick={() => changeLanguage('en')}>EN</span>

                </div>
            </div>
        </div>
    )
}

import React, { useEffect, useReducer } from 'react';
import './cp-awc.scss';
import TimeInput from './TimeInput';
import Optimizer from './Optimizer';

import { useCyclingDynamics } from '../../context/CyclingDynamicsContext';

const initialState = {
    rows: 1,
    data: [
        { zeit: '', leistung: '', arbeit: '' },
    ],
};

function tableReducer(state, action) {
    switch (action.type) {
        case 'addRow':
            return {
                ...state,
                rows: state.rows + 1,
                data: [...state.data, { zeit: '', leistung: '', arbeit: '' }],
            };
        case 'removeRow':
            if (state.rows <= 1) return state;
            const newData = [...state.data];
            newData.pop();
            return {
                ...state,
                rows: state.rows - 1,
                data: newData,
            };
        case 'updateData':
            const { rowIndex, name, value } = action.payload;
            const updatedData = [...state.data];
            updatedData[rowIndex][name] = value;
            return {
                ...state,
                data: updatedData,
            };
        case 'setData':
            const data = action.payload;
            console.log(data);
            return {
                ...state,
                rows: data.length,
                data: data
            }
        default:
            return state;
    }
}

const convertTimeToSeconds = (time) => {
    const [hours, minutes, seconds] = time.split(':');
    return parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
}




export default function CpAwcTable({ setData }) {
    const [state, dispatch] = useReducer(tableReducer, initialState);
    const { updateScenario } = useCyclingDynamics();

    useEffect(() => {
        const data = [{
            zeit: '00:01:00',
            leistung: 500,
        },
        {
            zeit: '00:03:00',
            leistung: 400
        },
        {
            zeit: '00:05:00',
            leistung: 370,
        },
        {
            zeit: '00:20:00',
            leistung: 300,
        }];

        dispatch({ type: 'setData', payload: data })
    }, [])

    useEffect(() => {


        const tmpData = state.data.filter((row) => row.zeit !== '' && row.leistung !== '' && row.zeit !== undefined && row.leistung !== undefined).map((row) => {
            return [
                convertTimeToSeconds(row.zeit),
                row.leistung,
            ];
        });

        updateScenario(tmpData, 'dataPoints', 'scenario1');
    }, [state]);

    const createItems = () => {
        return state.data.map((row, rowIndex) => (
            <div className="cp-awc-table__row" key={rowIndex}>
                <div className="first">{rowIndex + 1}</div>
                <div>
                    <TimeInput
                        value={row.zeit}
                        onChange={(inputTime) => {
                            dispatch({
                                type: 'updateData',
                                payload: {
                                    rowIndex,
                                    name: 'zeit',
                                    value: inputTime,
                                },
                            });
                        }}
                    />
                </div>
                <div>
                    <input
                        type="number"
                        value={row.leistung}
                        onChange={(e) => {
                            dispatch({
                                type: 'updateData',
                                payload: {
                                    rowIndex,
                                    name: 'leistung',
                                    value: e.target.value,
                                },
                            });
                        }}
                    />
                </div>
                <div>{convertTimeToSeconds(row.zeit)}</div>
                <div>{convertTimeToSeconds(row.zeit) * row.leistung}</div>
            </div>
        ));
    };

    return (
        <div>
            <h2>Berechnung CP und AWC</h2>
            <div className="cp-awc-table">
                <div className="cp-awc-table__row">
                    <div className="first"></div>
                    <div>Zeit[hh:mm:ss]</div>
                    <div>Leistung[W]</div>
                    <div>Zeit[s]</div>
                    <div>Arbeit[J]</div>
                </div>
                {createItems()}
            </div>
            <div>
                <button onClick={() => dispatch({ type: 'removeRow' })}>Remove</button>
                <button onClick={() => dispatch({ type: 'addRow' })}>Add</button>
            </div>
        </div>
    );
}

import { ANTRIEBSVERLUST, ERDANZIEHUNG, WIRKUNGSGRAD } from '../../helpers/constants';
import { round } from '../../helpers/helpers';
import QubicSolver from '../../helpers/qubic-solver';

export default class GeneralCalculator {

    constructor(scenarioState) {
        this.scenarioState = scenarioState;
    }


    calculateTotalPower(decimalPlaces = 0) {

        const totalPower = (this.scenarioState.zielleistung - ANTRIEBSVERLUST) * WIRKUNGSGRAD;

        return round(totalPower, decimalPlaces);
    }


    calculateSystemWeight(state, decimalPlaces = 1) {
        const { bodyWeight, bikeWeight, additionalWeight, clothingWeight } = this.scenarioState;
        const systemWeight = bodyWeight + bikeWeight + clothingWeight + additionalWeight;
        return round(systemWeight, decimalPlaces)
    }

    calculateInclinationAngle(decimalPlaces = 2) {
        const { distance, heightDifference } = this.scenarioState;
        if (heightDifference === 0) {
            return 0.00;
        }
        const angleInRadians = Math.asin(heightDifference / (distance * 1000));

        const angleInDegrees = (angleInRadians * 180) / Math.PI;
        const inclinationAngle = angleInDegrees;
        return round(inclinationAngle, decimalPlaces);
    }

    calculateGroundSpeed(decimalPlaces = 1) {

        const groundSpeed = this.calculateVelocityZeitberechnung(null);
        return round(groundSpeed, decimalPlaces);

    }



    calculateTimeInSeconds(time) {
        const velocity = this.calculateGroundSpeed(null, null);
        const { distance } = this.scenarioState;
        const timeInSeconds = distance * 1000 / (velocity / 3.6);
        return round(timeInSeconds, 0);
    }

    calculateSlopeResistance(decimalPlaces = 1) {
        const { distance, heightDifference } = this.scenarioState;
        if (heightDifference === 0) {
            return 0;
        }
        const angleInRadians = Math.asin(heightDifference / (distance * 1000));
        const systemWeight = this.calculateSystemWeight(3);
        if (systemWeight === 0) {
            return 0;
        }

        const slopeResistance = systemWeight * ERDANZIEHUNG * Math.sin(angleInRadians);

        return round(slopeResistance, decimalPlaces);
    }

    calculateSlopeResistancePower(decimalPlaces = 0) {

        const slopeResistance = this.calculateSlopeResistance(null);


        const groundSpeed = this.calculateGroundSpeed(null);

        const slopeResistancePower = groundSpeed * slopeResistance / 3.6;

        return round(slopeResistancePower, decimalPlaces);
    }

    calculateBSA(decimalPlaces = 2) {
        const { height, bodyWeight } = this.scenarioState;

        const BSA = 0.00949 * Math.pow(height * 100, 0.655) * Math.pow(bodyWeight, 0.441);
        return round(BSA, decimalPlaces);
    }

    calculateArea(decimalPlaces = 2) {

        const area = 0.3176 * this.calculateBSA(null) - 0.1478;
        return round(area, decimalPlaces);

    }

    calculateAirVelocity(decimalPlaces = 1) {
        const windVelocity = this.calculateWindVelocity(null);
        const groundSpeed = this.calculateGroundSpeed(null);
        const airVelocity = groundSpeed - windVelocity;
        return round(airVelocity, decimalPlaces);
    }

    calculateWindVelocity(decimalPlaces = 1) {
        const { tailwind } = this.scenarioState;
        const windVelocity = tailwind;
        return round(windVelocity, decimalPlaces);
    }

    calculateAirResistance(decimalPlaces = 1) {
        const { airDensity, bikePosition } = this.scenarioState;
        const airVelocity = this.calculateAirVelocity(null);
        const area = this.calculateArea(null);
        const airResistance = 0.5 * airDensity * Math.pow((airVelocity / 3.6), 2) * area * bikePosition;
        return round(airResistance, decimalPlaces);
    }

    calculateAirResistancePower(decimalPlaces = 0) {
        const airResistance = this.calculateAirResistance(null);


        const airResistancePower = this.calculatePower(airResistance, null);
        return round(airResistancePower, decimalPlaces);
    }

    calculatePower(resistance, decimalPlaces = 2) {
        const groundSpeed = this.calculateGroundSpeed(null);
        const power = groundSpeed * resistance / 3.6;
        return power;
    }

    calculateSlopeResistancePercentage(decimalPlaces = 1) {
        const totalResistance = this.calculateTotalResistance(null);
        const slopeResistance = this.calculateSlopeResistance(null);
        return round(slopeResistance / totalResistance * 100, decimalPlaces);
    }

    calculateAirResistancePercentage(decimalPlaces = 1) {
        const totalResistance = this.calculateTotalResistance();
        const airResistance = this.calculateAirResistance();
        return round(airResistance / totalResistance * 100, decimalPlaces);
    }

    calculateRollwiderstandPercentage(decimalPlaces = 1) {
        const totalResistance = this.calculateTotalResistance();
        const rollwiderstand = this.calculateRollwiderstand();
        return round(rollwiderstand / totalResistance * 100, decimalPlaces);
    }

    calculateTotalResistance(decimalPlaces = 1) {

        const slopeResistance = this.calculateSlopeResistance(null);
        const airResistance = this.calculateAirResistance(null);
        const rollwiderstand = this.calculateRollwiderstand(null);

        const totalResistance = slopeResistance + airResistance + rollwiderstand;

        return round(totalResistance, decimalPlaces);
    }

    calculateVelocityZeitberechnung2(decimalPlaces = 1) {
        const totalPower = this.calculateTotalPower(null);

        const slopeResistance = this.calculateSlopeResistance(null);
        const rollwiderstand = this.calculateRollwiderstand(null);

        const { airDensity, bikePosition } = this.scenarioState;
        const area = this.calculateArea(null);
        const airResistancePart = 0.5 * airDensity * area * bikePosition;

        const slopeAndRoll = slopeResistance + rollwiderstand;

        const result = QubicSolver(airResistancePart, 0, slopeAndRoll, -totalPower);
        const velocity = result[0].real * 3.6;


        return round(velocity, decimalPlaces);
    }

    calculateVelocityZeitberechnung(decimalPlaces = 1) {
        const { tailwind } = this.scenarioState;

        const tmpTailwind = tailwind / 3.6;

        const totalPower = this.calculateTotalPower(null);

        const slopeResistance = this.calculateSlopeResistance(null);
        const rollwiderstand = this.calculateRollwiderstand(null);

        const { airDensity, bikePosition } = this.scenarioState;
        const area = this.calculateArea(null);
        const airResistancePart = 0.5 * airDensity * area * bikePosition;

        const slopeAndRoll = slopeResistance + rollwiderstand;

        const a = airResistancePart
        const b = (-2) * airResistancePart * tmpTailwind
        const c = airResistancePart * Math.pow(tmpTailwind, 2) + slopeAndRoll
        const d = - totalPower

        const result = QubicSolver(a, b, c, d);
        const velocity = result[0].real * 3.6;

        return round(velocity, decimalPlaces);
    }


    calculateBruttoTotalPower(decimalPlaces = 0) {

        return this.scenarioState.zielleistung;
    }

    calculateRollwiderstand(decimalPlaces = 1) {

        const { cr, heightDifference, distance } = this.scenarioState;
        const systemWeight = this.calculateSystemWeight(null);
        const angleInRadians = Math.asin(heightDifference / (distance * 1000));
        const rollwiderstand = systemWeight * ERDANZIEHUNG * cr * Math.cos(angleInRadians);
        return round(rollwiderstand, decimalPlaces);


    }

    calculateRollwiderstandPower(decimalPlaces = 0) {

        const rollwiderstand = this.calculateRollwiderstand(null);


        const rollwiderstandPower = this.calculatePower(rollwiderstand, null);
        return round(rollwiderstandPower, decimalPlaces);
    }

    calculateRelativePower(decimalPlaces = 1) {

        const bruttoTotalPower = this.calculateBruttoTotalPower(null);
        const bodyWeight = this.scenarioState.bodyWeight;

        const relativePower = bruttoTotalPower / bodyWeight;

        return round(relativePower, decimalPlaces);
    }

}

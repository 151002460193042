import React, { memo } from 'react';
import './number-input.scss';

const NumberInput = memo(({ label, value, onChange }) => {

    const handleChange = (e, dataKey) => {
        const newValue = parseFloat(e.target.value);
        if (!isNaN(newValue)) {
            onChange({ ...value, [dataKey]: newValue });
        }
    };

    return (
        <div className="time-input">
            <label>{label}</label>
            <div className="time-input__input-fields">
                <input
                    type="number"
                    value={value.hours}
                    onChange={(e) => handleChange(e, 'hours')}
                />
                <input
                    type="number"
                    value={value.minutes}
                    onChange={(e) => handleChange(e, 'minutes')}
                />
                <input
                    type="number"
                    value={value.seconds}
                    onChange={(e) => handleChange(e, 'seconds')}
                />
            </div>

        </div>
    );
});

export default NumberInput;

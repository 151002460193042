
const round = (value, decimalPlaces) => {
    if (decimalPlaces !== null) {
        const roundedNumber = Math.round(value * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
        return roundedNumber;

    } else {
        return value;
    }
}


export { round }
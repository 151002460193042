import React from 'react';
import './grey-box.scss';

export default function GreyBox(props) {

    const { className, children } = props;
    return (
        <div className={`grey-box grid-item ${className}`}>{children}</div>
    )
}


GreyBox.defaultProps = {
    children: null
}
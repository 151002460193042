import React, { useEffect, useState } from 'react';
import './page.scss';
import GreyBox from '../components/GreyBox';
import ScenarioInput from '../components/input/ScenarioInput';
import InfoTable from '../components/InfoTable';
import { useCyclingDynamics } from '../context/CyclingDynamicsContext';
import PieChart from '../components/graphs/Graph';

import Chart from "chart.js/auto";

import { CategoryScale } from "chart.js";
import ScenarioResult from '../components/ScenarioResult';
import ChartDataProvider from '../context/ChartDataProvider';

import LineGraph from '../components/graphs/LineGraph';


import LeistungsBerechnungCalculator from '../components/calculator/LeistungsBerechnungCalculator';
import { useTranslation } from 'react-i18next';

import FormulaPrinter from '../components/formula/FormulaPrinter';

import { ERDANZIEHUNG, ANTRIEBSVERLUST, WIRKUNGSGRAD, steigungswiderstandFormula, rollwiderstandFormula, luftwiderstandFormula, PnetFormula, PbrutFormula } from '../helpers/constants';

export default function Leistungsberechnung() {

    Chart.register(CategoryScale);
    const { state } = useCyclingDynamics();
    const { t } = useTranslation();


    const scenario1 = new LeistungsBerechnungCalculator(state['scenario1']);
    const scenario2 = new LeistungsBerechnungCalculator(state['scenario2']);

    const chartDataProvider = new ChartDataProvider(scenario1, scenario2);



    const steigungsWiderstandRows = [
        {
            label: t("Systemgewicht"),
            key: 'systemgewicht',
            valueScenario1: scenario1.calculateSystemWeight(),
            valueScenario2: scenario2.calculateSystemWeight(),
            formula: 'm',
            unit: 'kg'
        },
        {
            label: t("Steigungswinkel"),
            valueScenario1: scenario1.calculateInclinationAngle(),
            valueScenario2: scenario2.calculateInclinationAngle(),
            formula: '\\alpha',
            unit: '°'
        },
        {
            label: t("Erdanziehung"),
            valueScenario1: ERDANZIEHUNG,
            valueScenario2: ERDANZIEHUNG,
            formula: 'g',
            unit: 'm/s2'
        },
        {
            label: t("Bodengeschwindigkeit"),
            valueScenario1: scenario1.calculateGroundSpeed(),
            valueScenario2: scenario2.calculateGroundSpeed(),
            formula: 'v',
            unit: 'km/h'
        },
        {
            label: t("Steigungswiderstand"),
            valueScenario1: scenario1.calculateSlopeResistance(),
            valueScenario2: scenario2.calculateSlopeResistance(),
            formula: 'F_{\\text{slope}}',
            unit: 'N'
        },
        {
            label: t("Leistung Steigungswiderstand"),
            valueScenario1: scenario1.calculateSlopeResistancePower(),
            valueScenario2: scenario2.calculateSlopeResistancePower(),
            formula: 'P_{\\text{slope}}',
            unit: 'W'
        },
        {
            label: t("Prozentualer Anteil Steigungswiderstand"),
            valueScenario1: scenario1.calculateSlopeResistancePercentage(),
            valueScenario2: scenario2.calculateSlopeResistancePercentage(),
            formula: null,
            unit: '\\%'
        },
    ]


    const luftwiderstandRow = [
        {
            label: t("Luftdichte"),
            key: 'systemgewicht',
            valueScenario1: state['scenario1']['airDensity'],
            valueScenario2: state['scenario2']['airDensity'],
            formula: '\\rho',
            unit: 'kg/m3'
        },
        {

            label: t("Körperoberfläche"),
            valueScenario1: scenario1.calculateBSA(),
            valueScenario2: scenario2.calculateBSA(),
            formula: 'BSA',
            unit: 'm2'
        },
        {

            label: t("Frontalfläche"),
            valueScenario1: scenario1.calculateArea(),
            valueScenario2: scenario2.calculateArea(),
            formula: 'A',
            unit: 'm2'
        },
        {
            label: t("Strömungswiderstandskoeffizient"),
            valueScenario1: state['scenario1']['bikePosition'],
            valueScenario2: state['scenario2']['bikePosition'],
            formula: 'C_d',
            unit: ''
        },
        {
            label: t("Bodengeschwindigkeit"),
            valueScenario1: scenario1.calculateGroundSpeed(),
            valueScenario2: scenario2.calculateGroundSpeed(),
            formula: 'v',
            unit: 'km/h'
        },
        {
            label: t("Windgeschwindigkeit"),
            valueScenario1: scenario1.calculateWindVelocity(),
            valueScenario2: scenario2.calculateWindVelocity(),
            formula: 'v_{\\text{wind}}',
            unit: 'km/h'
        },
        {
            label: t("Luftgeschwindigkeit"),
            valueScenario1: scenario1.calculateAirVelocity(),
            valueScenario2: scenario2.calculateAirVelocity(),
            formula: 'v_{\\text{air}}',
            unit: 'km/h'
        },
        {
            label: t("Luftwiderstand"),
            valueScenario1: scenario1.calculateAirResistance(),
            valueScenario2: scenario2.calculateAirResistance(),
            formula: 'F_{\\text{air}}',
            unit: 'N'
        },
        {
            label: t("Leistung Luftwiderstand"),
            valueScenario1: scenario1.calculateAirResistancePower(),
            valueScenario2: scenario2.calculateAirResistancePower(),
            formula: 'P_{\\text{air}}',
            unit: 'W'
        },
        {
            label: t("Prozentualer Anteil Luftwiderstand"),
            valueScenario1: scenario1.calculateAirResistancePercentage(),
            valueScenario2: scenario2.calculateAirResistancePercentage(),
            formula: null,
            unit: '\\%'
        },
    ];




    const rollwiderstandTable = [
        {
            label: t("Rollwiderstandskoeffizient"),
            key: 'systemgewicht',
            valueScenario1: state['scenario1']['cr'],
            valueScenario2: state['scenario2']['cr'],
            formula: 'C_r',
            unit: ''
        },
        {
            label: t("Bodengeschwindigkeit"),
            valueScenario1: scenario1.calculateGroundSpeed(),
            valueScenario2: scenario2.calculateGroundSpeed(),
            formula: 'v',
            unit: 'km/h'
        },
        {
            label: t("Rollwiderstand"),
            valueScenario1: scenario1.calculateRollwiderstand(),
            valueScenario2: scenario2.calculateRollwiderstand(),
            formula: 'F_{\\text{roll}}',
            unit: 'N'
        },
        {
            label: t("Leistung Rollwiderstand"),
            valueScenario1: scenario1.calculateRollwiderstandPower(),
            valueScenario2: scenario2.calculateRollwiderstandPower(),
            formula: 'P_{\\text{roll}}',
            unit: 'W'
        },
        {
            label: t("Prozentualer Anteil Rollwiderstand"),
            valueScenario1: scenario1.calculateRollwiderstandPercentage(),
            valueScenario2: scenario2.calculateRollwiderstandPercentage(),
            formula: null,
            unit: '\\%'
        },
    ];

    const gesamtleistungTable = [
        {
            label: t("Netto-Gesamtleistung"),
            key: 'systemgewicht',
            valueScenario1: scenario1.calculateTotalPower(),
            valueScenario2: scenario2.calculateTotalPower(),
            formula: 'P_{\\text{net}}',
            unit: 'W'
        },
        {
            label: t("Wirkungsgrad"),
            valueScenario1: WIRKUNGSGRAD,
            valueScenario2: WIRKUNGSGRAD,
            formula: '',
            unit: '\\%'
        },
        {
            label: t("Antriebswiderstand"),
            valueScenario1: ANTRIEBSVERLUST,
            valueScenario2: ANTRIEBSVERLUST,
            formula: '',
            unit: 'W'
        },
        {
            label: t("Brutto-Gesamtleistung"),
            valueScenario1: scenario1.calculateBruttoTotalPower(),
            valueScenario2: scenario2.calculateBruttoTotalPower(),
            formula: 'P_{\\text{brut}}',
            unit: 'W'
        },
        {
            label: t("Relative Leistung"),
            valueScenario1: scenario1.calculateRelativePower(),
            valueScenario2: scenario2.calculateRelativePower(),
            formula: 'P_{\\text{rel}}',
            unit: 'W/kg'
        },
    ];


    const [chartData, setChartData] = useState(null);

    const [gesamtwiderstandScenario, setGesamtwiderstandScenario] = useState(null);
    const [powerToVelocityScenario, setPowerToVelocityScenario] = useState(null);


    useEffect(() => {

        const tmpPowerToVelocityScenario1 = chartDataProvider.calculatePowerGraphParameter(state);
        setPowerToVelocityScenario(tmpPowerToVelocityScenario1);

        const tmpGesamtwiderstandScenario = chartDataProvider.calculatePowerPerVelocity(state);
        setGesamtwiderstandScenario(tmpGesamtwiderstandScenario);
        setChartData(chartDataProvider.getStackedBarChartData());


    }, [state]);

    return (
        <div className="page grid-container">
            <GreyBox className="medium-6">
                <ScenarioInput title={t("Szenario 1")} scenarioName="scenario1" >
                    <ScenarioResult
                        gesamtleistung={scenario1.calculateBruttoTotalPower()}
                        relative_leistung={scenario1.calculateRelativePower()}
                        sekunden={scenario1.calculateTimeInSeconds(state['scenario1']['time'])}
                    ></ScenarioResult>
                </ScenarioInput>
            </GreyBox>
            <GreyBox className="medium-6">
                <ScenarioInput title={t("Szenario 2")} scenarioName="scenario2">
                    <ScenarioResult
                        gesamtleistung={scenario2.calculateBruttoTotalPower()}
                        relative_leistung={scenario2.calculateRelativePower()}
                        sekunden={scenario2.calculateTimeInSeconds(state['scenario2']['time'])}
                    ></ScenarioResult>
                </ScenarioInput>
            </GreyBox>
            <GreyBox className="medium-6">
                <InfoTable title={t("Steigungswiderstand")}
                    rows={steigungsWiderstandRows}
                    explanation={
                        <FormulaPrinter
                            formula={steigungswiderstandFormula} />}
                />
                <InfoTable title={t("Luftwiderstand")} rows={luftwiderstandRow} explanation={
                    <FormulaPrinter
                        formula={luftwiderstandFormula} />} />
                <InfoTable title={t("Rollwiderstand")} rows={rollwiderstandTable} explanation={
                    <FormulaPrinter
                        formula={rollwiderstandFormula} />} />
                <InfoTable title={t("Gesamtleistung")} rows={gesamtleistungTable} explanation={<>
                    <FormulaPrinter
                        formula={PnetFormula} />
                    <FormulaPrinter
                        formula={PbrutFormula} />
                </>
                } />
            </GreyBox>

            <div className="medium-6">
                <GreyBox className="chart-box">

                    {chartData !== null ? <PieChart chartData={chartData} title={t("Vergleich der Szenarien")} /> : null}
                </GreyBox>


                <GreyBox className="chart-box">
                    {gesamtwiderstandScenario !== null ? <PieChart chartData={gesamtwiderstandScenario.scenario1} xLabel={true} title={t("Szenario 1 - Prozent des Gesamtwiderstands über Geschwindigkeit")} />
                        : null}
                </GreyBox>

                <GreyBox className="chart-box">
                    {powerToVelocityScenario !== null ? <LineGraph parameter={powerToVelocityScenario.scenario1} title={t("Szenario 1 - Leistung über Geschwindigkeit")} />
                        : null}
                </GreyBox>

                <GreyBox className="chart-box">
                    {gesamtwiderstandScenario !== null ? <PieChart chartData={gesamtwiderstandScenario.scenario2} xLabel={true} title={t("Szenario 2 - Prozent des Gesamtwiderstands über Geschwindigkeit")} /> : null}
                </GreyBox>

                <GreyBox className="chart-box">
                    {powerToVelocityScenario !== null ? <LineGraph parameter={powerToVelocityScenario.scenario2} title={t("Szenario 2 - Leistung über Geschwindigkeit")} />
                        : null}
                </GreyBox>
            </div>

        </div>
    )
}

import React from 'react';
import { Line } from 'react-chartjs-2';

const CriticalPowerChart = ({ AWC, CP, dataPoints }) => {
    const chartData = generateChartData(AWC, CP);

    function power_time([AWC, CP]) {
        return (power) => AWC / (power - CP);
    }

    function time_power([AWC, CP]) {
        return (time) => AWC / time + CP;
    }


    const chartOptions = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Time', // Your x-axis description
                },
                ticks: {
                    maxTicksLimit: 16,
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Power', // Your y-axis description
                },
                min: 0, // Set the minimum value for the y-axis
                max: 1500, // Set the maximum value for the y-axis
            },
        },
    };

    function generateChartData(AWC, CP) {

        const xValues = [];
        const yValues = [];

        // Generate time values, e.g., from 0 to 10 with a step of 0.1
        for (let x = 0; x <= 1800; x += 10) {
            xValues.push(x);
            yValues.push(time_power([AWC, CP])(x));
        }

        const asymptoteValue = CP;
        const asymptoteData = xValues.map(() => asymptoteValue);

        return {
            labels: xValues,
            datasets: [
                {
                    label: 'Power vs. Time',
                    data: yValues,
                    fill: false,
                    borderColor: 'rgba(75,192,192,1)',
                    pointRadius: 0, // Set the point radius to 0 to hide data points
                    pointStyle: 'line', // Set point style to 'line' to show only the line
                },
                {
                    label: 'Critical Power',
                    data: asymptoteData,
                    fill: false,
                    borderColor: 'rgba(255,0,0,1)', // Adjust the color
                    borderWidth: 1, // Adjust the line width
                    borderDash: [5, 5], // Add a dashed line style if desired
                    pointRadius: 0,
                    pointStyle: 'line',
                },
                {
                    label: 'Single Point',
                    data: dataPoints, // Add your single point data here
                    pointBackgroundColor: 'red', // Set the point color
                    pointRadius: 3, // Set the point size
                    showLine: false, // Hide the line connecting the points
                },
            ]
        };
    }


    return (
        <div>
            <h2>Critical Power Chart</h2>
            <Line data={chartData} options={chartOptions} />
        </div>
    );
};

export default CriticalPowerChart;

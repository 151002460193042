import React from 'react';
import { Line } from 'react-chartjs-2';

const PowerTimeChart = ({ coefficients, dataPoints }) => {
    if (!coefficients) {
        return null;
    }
    const chartData = generateChartData(coefficients);



    const chartOptions = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Time [s]',
                },
                ticks: {
                    maxTicksLimit: 16,
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Work [J]',
                },
            },
        },
    };

    function generateChartData(coefficients) {
        if (!coefficients) {
            return {};
        }


        const timeValues = [];
        const workValues = [];

        for (let time = 0; time <= 1800; time += 10) {
            timeValues.push(time);
        }


        const formattedDataPoints = dataPoints.map(point => {
            const power = point[0];
            const time = point[1];
            const work = power * time; // Calculating work
            return { x: time, y: work }; // x is time, y is calculated work
        });

        console.log(dataPoints)



        console.log(coefficients)
        const regressionLine = [{ x: 0, y: coefficients[0] }, { x: 1800, y: coefficients[1] * 1800 + coefficients[0] }];

        return {
            labels: timeValues,
            datasets: [
                {
                    label: 'lineare Regression',
                    data: regressionLine,
                    fill: false,
                    borderColor: 'blue', // Choose a color that stands out
                    pointRadius: 0, // No points, only the line
                    borderWidth: 2, // Adjust line thickness
                },
                {
                    label: 'Messpunkt',
                    data: dataPoints, // Add your single point data here
                    pointBackgroundColor: 'red', // Set the point color
                    pointRadius: 3, // Set the point size
                    showLine: false, // Hide the line connecting the points
                },
            ]
        };
    }


    return (
        <div>
            <h2>Work-Time Chart</h2>
            <Line data={chartData} options={chartOptions} />
        </div>
    );
};

export default PowerTimeChart;
